export const breakpoints = {
    tablet: '768px',
    desktop: '1200px',
}

export const breakpointsRaw = {
    tablet: '768',
    desktop: '1200',
}

export const headerHeights = {
    mobile: '50px',
    tablet: '80px',
    desktop: '100px',
}

export const colors = {
    mobileColor: '#fff',
    desctopColor: '#222',
}
