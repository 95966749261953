import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

import { useLocale } from './useLocale'
import { isDefaultLocale } from 'i18n/localeConfigs'

const hasPrefix = (link, locale) => {
    const regex = RegExp(`^/${locale}/`)

    return regex.test(link)
}

export const getDefaultLocaleLink = (to, locale) => {
    if (!isDefaultLocale(locale)) {
        return to.slice(3)
    }

    return to
}

export const getLocalizedLink = (to, locale) => {
    const link = to ? to : ''

    if (hasPrefix(link, locale)) {
        return link
    }

    const linkPrefix = locale === 'uk' ? '' : `/${locale}`
    const linkWithLocale = `${linkPrefix}${link}`

    return linkWithLocale
}

/**
 * useLocalizedLink is used to determine a link prefix based on raw link and current local
 * @param {string} to - the raw value of "to" prop
 */
export const useLocalizedLink = (to) => {
    const currentLocal = useLocale()

    return getLocalizedLink(to, currentLocal)
}

/**
 * Link component is used to prepend current 'locale' prefix to each Link
 * The logic of determining prefix is implemented by "useLocalizedLink" hook
 */
export const Link = ({ to, ...props }) => {
    const toWithLocale = useLocalizedLink(to)

    return <GatsbyLink {...props} to={toWithLocale} />
}
