import { useLocale } from 'i18n/useLocale'
import ukTranslation from 'i18n/translations/uk'
import enTranslation from 'i18n/translations/en'

const translationsMap = {
    uk: ukTranslation,
    en: enTranslation,
}

export const useTranslations = () => {
    const locale = useLocale()

    return translationsMap[locale]
}
